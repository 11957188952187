import React from 'react'
import Seo from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/header";
import Footer from "../components/footer";

const Contact = () => {

  return(
    <Layout classmain='contactus'>
    <Seo title="Contact us" description=""/>
      <Header/>
      <div className='contact-out ctc'>
      <div className='contact' id="contact">
        <div className='contact__container container'>
          <div className='contact__row row'>
            <div className='contact__col col-md-6 col-12'>
                <h3 className='contact__title'>Contact us</h3>
                <p class="pf">4518 W Swann Avenue, Tampa, FL 33609</p>
                <a target="_blank" href="mailto:contact@autolytics.io">contact@autolytics.io</a>
                <a target="_blank" href="tel:9178602530">917.860.2530</a>
                <a class="fb" target="_blank" href="https://www.facebook.com/Autolytics-109896801834832"><img src="/images/fb.svg" /></a>
            </div>
          </div>
        </div>
      </div>
      </div>
      <Footer/>
    </Layout>
  )
}

export default Contact